import cn from 'classnames';
import { Button, Icon } from 'components';
import styles from './ProductRevealButton.module.css';

interface ProductRevealButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string | React.ReactNode;
  detailIsSelected: boolean;
}

export const ProductRevealButton: React.FC<ProductRevealButtonProps> = ({
  onClick,
  text,
  detailIsSelected,
}) => {
  return (
    <Button onClick={onClick} className={styles.wrapper}>
      <span className={styles.text}>{text}</span>
      <Icon
        icon="arrow"
        className={cn(styles.icon, detailIsSelected && styles.detailIsSelected)}
      />
    </Button>
  );
};
