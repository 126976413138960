import cn from 'classnames';
import { Button } from 'components';
import { SelectedProduct } from 'components/molecules/TryOnUserInterface/TryOnUserInterface';
import { EventCategories, useTrackingEvent } from 'hooks';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { localeString } from '../../../sanity/locales';
import type * as Schema from '../../../sanity/schema';
import styles from './ProductCategoryList.module.css';

interface ProductCategoryListProps {
  productCategories?: Array<Schema.Category>;
  productCategory?: string;
  setProductCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProductIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProducts: Array<SelectedProduct>;
}

const ActiveMarker: React.FC<{
  selectedProducts: Array<SelectedProduct>;
  category: string;
}> = ({ selectedProducts, category }) => {
  const currentProduct = selectedProducts.find(
    (product) => product.category === category
  );
  if (currentProduct) {
    return (
      <span
        style={{ backgroundColor: currentProduct?.product.sku_colour }}
        className={styles.activeMarker}
      ></span>
    );
  }
  return <></>;
};

export const ProductCategoryList: React.FC<ProductCategoryListProps> = ({
  productCategories,
  productCategory,
  setProductCategory,
  setProductIsSelected,
  selectedProducts,
}) => {
  const [activeCategory, setActiveCategory] = useState<string | undefined>('');
  const event = useTrackingEvent();

  useEffect(() => {
    setActiveCategory(productCategory);
  }, [productCategory]);

  const onSetProductCategory = (id: string) => {
    setProductIsSelected(false);
    setProductCategory(id);
    setActiveCategory(id);
  };

  return (
    <>
      {productCategories?.length && productCategories.length === 1 ? (
        <div className={cn(styles.wrapper, styles.emptyWrapper)}></div>
      ) : (
        <ul className={styles.wrapper}>
          {productCategories?.map((category: Schema.Category) => (
            <li key={nanoid()} className={styles.listItem}>
              <Button
                onClick={() => {
                  event(
                    EventCategories.Beauty,
                    `Clicks on ${category.category_section} category`,
                    category.category_name?.en || '',
                    false
                  );
                  onSetProductCategory(category._id);
                }}
                className={cn(
                  styles.button,
                  activeCategory === category._id && styles.isActive
                )}
              >
                {localeString(category.category_name)}
                <ActiveMarker
                  category={category._id}
                  selectedProducts={selectedProducts}
                />
              </Button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
