import { ExperienceTemplate } from 'components';

interface ShadeFinderPageProps {
  handleLanguageSelect: (languageCode: string) => void;
}

export const ShadeFinderPage: React.FC<ShadeFinderPageProps> = ({
  handleLanguageSelect,
}) => {
  return (
    <ExperienceTemplate
      isShadeFinder={true}
      handleLanguageSelect={handleLanguageSelect}
    />
  );
};

export default ShadeFinderPage;
