import groq from 'groq';

export const MAKEUP_QUERY = groq`
*[_type == "category" && category_section==$selectedCategory] | order(order asc) {
    _id,
    category_name,
    category_section,
    products[]-> {
      _id,
      product_name,
      product_copy,
      product_description,
      hide_product_name,
      skus[]-> {
        _id,
        sku_code,
        sku_name,
        sku_colour,
        sku_image
      }
    }
   }
`;

export const LOOK_QUERY = groq`
*[_type == "look_category"  && category_section==$selectedCategory] | order(order asc) {
    category_name,
    _id,
    looks[]-> {
      look_name,
      look_id,
      _id,
      skus[]-> {
        _id,
        sku_name,
        sku_colour,
        sku_code,
        sku_image,
        "product":*[_type=='product' && ^._id in skus[]._ref][0]{ 
          product_name,
          product_prices,
          hide_product_name 
        } 
      }
    }
}
`;

// export const SHADEFINDER_QUERY = groq`
// *[_type == "category" && category_section=='Shadefinder'] | order(order asc) {
//     _id,
//     category_name,
//     products[]-> {
//       _id,
//       product_name,
//       product_copy,
//       product_description,
//       hide_product_name,
//       product_prices,
//       skus[]-> {
//         _id,
//         sku_code,
//         sku_name,
//         sku_colour,
//         sku_image,
//         markets[]-> {
//           market_code
//         },
//         skin_tone
//       }
//     }
//    }
// `;

export const HOWTO_QUERY = groq`
*[_type == "howTo" && skin_tone== $skinTone][0] {
  _id,
  title,
  copy,
  swatch,
  swatch_cn,
  ingredients,
  infographic,
  grid_shade_family,
  comparative_chart,
  comparative_chart_cn,
  key_visual,
  "sku":*[_type=='sku' && sku_code==$sku][0] { 
    _id,
    sku_code,
    sku_name,
    howto_images,
    "product":*[_type=='product' && ^._id in skus[]._ref][0]{ 
      product_name 
    } 
  } 
}
`;

// export const CONCEALER_QUERY = groq`
// *[_type == "category" && category_section=='Shadefinder' && category_type==$type] | order(order asc) {
//     _id,
//     category_name,
//     products[]-> {
//       _id,
//       product_name,
//       product_copy,
//       product_description,
//       hide_product_name,
//       product_prices,
//       "skus":*[_type=='sku' && _id in $sku_refs] {
//         _id,
//         sku_code,
//         sku_name,
//         sku_colour,
//         sku_image,
//         markets[]-> {
//           market_code
//         },
//         skin_tone,
//       }
//     }
//    }
// `;



export const SHADEFINDER_QUERY = groq`
*[_type == "category" && category_section=='Shadefinder' && category_type==$type] | order(order asc) {
    _id,
    category_name,
    products[]-> {
      _id,
      product_name,
      product_copy,
      product_description,
      hide_product_name,
      product_prices,
      skus[]-> {
        _id,
        sku_code,
        sku_name,
        sku_colour,
        sku_image,
        markets[]-> {
          market_code
        },
        skin_tone,
        matched_concealer
      }
    }
   }
`;

export const CONCEALER_QUERY = groq`
*[_type == "category" && category_section=='Shadefinder' && category_type==$type] | order(order asc) {
    _id,
    category_name,
    products[]-> {
      _id,
      product_name,
      product_copy,
      product_description,
      hide_product_name,
      product_prices,
      "skus":*[_type=='sku' && _id in $sku_refs] {
        _id,
        sku_code,
        sku_name,
        sku_colour,
        sku_image,
        markets[]-> {
          market_code
        },
        skin_tone,
      }
    }
   }
`;