import { Button } from 'components';
import { EASINGS, VARIANT_LABELS } from 'constants/animations';
import { motion, Variants } from 'framer-motion';
import { useQuery, useTrackingEvent } from 'hooks';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSettingsStore } from 'store/settingsStore';
import { ExperienceType } from 'types/shared';
import styles from './ShadeFinderMenu.module.css';

const wrapperVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0,
      delayChildren: 0.4,
      staggerChildren: 0.1,
      when: 'beforeChildren',
      staggerDirection: -1,
    },
  },
};

const linkVariants: Variants = {
  initial: { opacity: 0, y: '-50px' },
  animate: {
    opacity: 1,
    y: '0',
    transition: {
      duration: 1,
      ease: EASINGS.easeOut,
    },
  },
};

interface ShadeFinderMenuProps {
  onLinkSelected: (type: ExperienceType) => void;
}

export const ShadeFinderMenu: React.FC<ShadeFinderMenuProps> = ({
  onLinkSelected,
}) => {
  const intl = useIntl();
  const query = useQuery();
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const { isGucci } = useSettingsStore();
  const event = useTrackingEvent();

  useEffect(() => {
    const country = query.get('country');
    if (country) setShowFooter(false);
  }, [query]);

  return (
    <>
      <motion.ul
        {...VARIANT_LABELS}
        variants={wrapperVariants}
        className={styles.wrapper}
      >
        <motion.li variants={linkVariants} className={styles.menuListItem}>
          <div className={styles.stepHeading}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_one_heading',
            })}
          </div>
          <div className={styles.stepBody}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_one_message',
            })}
          </div>
        </motion.li>
        <motion.li variants={linkVariants} className={styles.menuListItem}>
          <div className={styles.stepHeading}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_two_heading',
            })}
          </div>
          <div className={styles.stepBody}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_two_message',
            })}
          </div>
        </motion.li>
        <motion.li variants={linkVariants} className={styles.menuListItem}>
          <div className={styles.stepHeading}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_three_heading',
            })}
          </div>
          <div className={styles.stepBody}>
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_step_three_message',
            })}
          </div>
        </motion.li>
        <motion.li variants={linkVariants} className={styles.menuListItem}>
          <Button
            onClick={() => {
              event('Shade Finder VTO', 'Click on Start Now', '/', isGucci);
              onLinkSelected('SHADE_FINDER');
            }}
            className={styles.startButton}
          >
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_start',
            })}
          </Button>
        </motion.li>
      </motion.ul>
      {showFooter && (
        <div className={styles.termsBar}>
          <a
            href={intl.formatMessage({
              id: 'messages.shade_finder_menu_privacy_link',
            })}
            target="_blank"
            rel="noreferrer"
            className="cookie-custom"
          >
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_privacy_button',
            })}
          </a>
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings cookie-custom"
          >
            {intl.formatMessage({
              id: 'messages.shade_finder_menu_cookies_button',
            })}
          </button>
        </div>
      )}
    </>
  );
};
