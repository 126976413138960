import styles from './ProductName.module.css';

interface ProductNameProps {
  skuName: string;
  productName: string;
  productPrice: string;
  hideProductName: boolean;
  isShoppable?: boolean;
  isUnavailable?: boolean;
}

export const ProductName: React.FC<ProductNameProps> = ({
  skuName,
  productName,
  productPrice,
  hideProductName,
  isShoppable,
  isUnavailable,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>{skuName}</div>
      {!hideProductName && (
        <div className={styles.subheading}>{productName}</div>
      )}
      {isShoppable && !isUnavailable && <div>{productPrice}</div>}
    </div>
  );
};
