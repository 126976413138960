import { LoaderSmall, ProductRevealButton } from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { AnimatePresence, motion } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { localeString } from 'sanity/locales';
import { LOOK_QUERY } from 'sanity/queries';
import { LooksQueryResult, LookType } from 'sanity/types';
import sanityClient from '../../../sanity/client';
import type * as Schema from '../../../sanity/schema';
import { LookItems } from '../LookItems/LookItems';
import { Looks } from '../Looks/Looks';
import { LooksCategoryList } from '../LooksCategoryList/LooksCategoryList';
import styles from './LooksUserInterface.module.css';

export const LooksUserInterface: React.FC = () => {
  const [data, setData] = useState<LooksQueryResult>();
  const [currentLook, setCurrentLook] = useState<string>();
  const [currentCategory, setCurrentCategory] = useState<string | undefined>(
    ''
  );
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();
  const event = useTrackingEvent();

  useEffect(() => {
    const oneTrustElement = document.getElementById('onetrust-consent-sdk');
    if (oneTrustElement) {
      oneTrustElement.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    // sanityClient
    //   .fetch<LooksQueryResult>(LOOK_QUERY, {
    //     selectedCategory: 'TryOn',
    //   })
    //   .then((data) => {
    //     setData(data)
    //     console.log('tryon data', JSON.stringify(data))
    //   })
    //   .catch(console.error);

    const dataPath = 'data/'
    // const dataPath = `https://stink-cdn-sh-1253554454.file.myqcloud.com/gucci-vto-sanity/`

    const query = `${dataPath}tryOnResult.json?nonStr=${Math.random()}`

    fetch(query)
      .then((response) => {
        return response.json()
      })
      .then(json => {
        console.log(json)
        setData(json)
      })
      .catch(console.error);

  }, []);

  useEffect(() => {
    if (data) {
      const firstItem = data[0];
      event(
        EventCategories.Beauty,
        `Clicks on Looks category`,
        firstItem.category_name?.en || '',
        false
      );
      setCurrentCategory(firstItem._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectLook = (look: string) => {
    event(
      EventCategories.Beauty,
      `Clicks on Look`,
      data
        ?.find((x) => x._id === currentCategory)
        ?.looks.find((x: Schema.Look) => x.look_id === look)?.look_name?.en ||
        '',
      false
    );
    setCurrentLook(look);
    window.YMK.applyMakeupByLook(look);
  };

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {!data ? (
          <LoaderSmall key="loader" color="white" className={styles.loader} />
        ) : (
          <motion.div
            key="interface"
            {...VARIANT_LABELS}
            variants={FADE}
            className={styles.wrapper}
          >
            {currentLook && (
              <>
                <ProductRevealButton
                  onClick={() => {
                    event(
                      EventCategories.Beauty,
                      isOpen ? 'Clicks on Show less' : 'Clicks on Show more',
                      'LOOKS',
                      false
                    );
                    setIsOpen(!isOpen);
                  }}
                  text={
                    isOpen ? (
                      <>
                        {intl.formatMessage({
                          id: 'messages.show_less',
                        })}
                      </>
                    ) : (
                      <>
                        {intl.formatMessage({
                          id: 'messages.show_more',
                        })}
                      </>
                    )
                  }
                  detailIsSelected={isOpen}
                />
                <LookItems
                  look={
                    (data
                      .find((category) => category._id === currentCategory)
                      ?.looks.find(
                        (look: Schema.Look) => look.look_id === currentLook
                      ) as LookType) || undefined
                  }
                  isOpen={isOpen}
                />
              </>
            )}
            <Looks
              looks={data?.find((x) => x._id === currentCategory)?.looks || []}
              onLookSelected={handleSelectLook}
              selectedLook={currentLook || ''}
              categoryName={localeString(
                data?.find((category) => category._id === currentCategory)
                  ?.category_name
              )}
              isOpen={isOpen}
            />
            <LooksCategoryList
              looksCategories={data}
              lookCategory={currentCategory}
              setCategory={setCurrentCategory}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
