import { createContext, Dispatch } from 'react';

export const PAGE_ACTIONS = {
  LOADING: 'LOADING',
  INTRO: 'INTRO',
  MENU: 'MENU',
  TERMS_OVERLAY: 'TERMS OVERLAY',
  TERMS: 'TERMS',
  EXPERIENCE: 'EXPERIENCE',
};

interface PageState {
  loadingIsActive: boolean;
  introIsActive: boolean;
  menuIsActive: boolean;
  termsOverlayIsActive: boolean;
  termsIsActive: boolean;
  experienceIsActive: boolean;
}

interface PageAction {
  type: string;
}

export const initialPageState = {
  loadingIsActive: true,
  introIsActive: false,
  menuIsActive: false,
  termsOverlayIsActive: false,
  termsIsActive: false,
  experienceIsActive: false,
};

export const PageStateContext = createContext<{
  pageState: PageState;
  pageDispatch: Dispatch<PageAction>;
}>({
  pageState: initialPageState,
  pageDispatch: () => null,
});

export const pageStateReducer = (pageState: PageState, action: PageAction) => {
  switch (action.type) {
    case PAGE_ACTIONS.LOADING: {
      return {
        loadingIsActive: true,
        introIsActive: false,
        termsOverlayIsActive: false,
        menuIsActive: false,
        termsIsActive: false,
        experienceIsActive: false,
      };
    }
    case PAGE_ACTIONS.INTRO: {
      return {
        loadingIsActive: false,
        introIsActive: true,
        termsOverlayIsActive: false,
        menuIsActive: false,
        termsIsActive: false,
        experienceIsActive: false,
      };
    }
    case PAGE_ACTIONS.TERMS_OVERLAY: {
      return {
        loadingIsActive: false,
        introIsActive: true,
        termsOverlayIsActive: true,
        menuIsActive: true,
        termsIsActive: false,
        experienceIsActive: false,
      };
    }
    case PAGE_ACTIONS.MENU: {
      return {
        loadingIsActive: false,
        introIsActive: false,
        menuIsActive: true,
        termsOverlayIsActive: false,

        termsIsActive: false,
        experienceIsActive: false,
      };
    }

    case PAGE_ACTIONS.TERMS: {
      return {
        loadingIsActive: false,
        introIsActive: false,
        termsOverlayIsActive: false,
        menuIsActive: false,
        termsIsActive: true,
        experienceIsActive: false,
      };
    }
    case PAGE_ACTIONS.EXPERIENCE: {
      return {
        loadingIsActive: false,
        introIsActive: false,
        termsOverlayIsActive: false,
        menuIsActive: false,
        termsIsActive: false,
        experienceIsActive: true,
      };
    }
  }
  return pageState;
};
