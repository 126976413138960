import cn from 'classnames';
import { Button } from 'components';
import { SHADE_FINDER } from 'constants/shadeFinder';
import { SelectedProduct } from 'components/molecules/TryOnUserInterface/TryOnUserInterface';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { localeString } from 'sanity/locales';
import { countryPrice } from 'sanity/prices';
import type * as Schema from '../../../sanity/schema';
import { useSettingsStore } from '../../../store/settingsStore';
import styles from './ProductGroup.module.css';

interface ProductGroupProps {
  group?: Schema.Product & { skus: Array<Schema.Sku> };
  onAddProductSku: (selectedProduct: SelectedProduct) => void;
  selectedProducts: Array<SelectedProduct>;
  isShadeFinder?: boolean;
  shadeFinderType?: string;
}

export const ProductGroup: React.FC<ProductGroupProps> = ({
  group,
  onAddProductSku,
  selectedProducts,
  isShadeFinder,
                                                            shadeFinderType
}) => {
  const intl = useIntl();
  const { currentCountry, currentRegion, experienceType } = useSettingsStore();

  const [shadeType, setShadeType] = useState<string | undefined>('');
  const [shadeLabel, setShadeLabel] = useState<string | undefined>('');

  const handleActiveState = (product: Schema.Sku) => {
    if (
      selectedProducts.some(
        (items) => items.product.sku_code === product.sku_code
      )
    )
      return true;
  };

  // useEffect(() => {
  //   if (selectedProducts.length !== 0) {
  //     setShadeType(
  //       intl.formatMessage({
  //         id: `messages.shade_finder_shade_${selectedProducts[0].product.shadeType}`,
  //       })
  //     );
  //     if (selectedProducts[0].product.index !== 1) {
  //       setShadeLabel(
  //         intl.formatMessage({
  //           id: `messages.shade_finder_shade_${
  //             selectedProducts[0].product.index + 1
  //           }`,
  //         })
  //       );
  //     } else {
  //       setShadeLabel(undefined);
  //     }
  //   }
  // }, [intl, isShadeFinder, selectedProducts]);

  useEffect(() => {
    if (selectedProducts.length !== 0) {
      if (shadeFinderType === SHADE_FINDER.FOUNDATION) {
        const product = selectedProducts.find(
          (x) => x.type === SHADE_FINDER.FOUNDATION
        );
        if (product) {
          setShadeType(
          intl.formatMessage({
            id: `messages.shade_finder_shade_${product.product.shadeType}`,
          })
          );
          if (product.product.index !== 1) {
            setShadeLabel(
            intl.formatMessage({
              id: `messages.shade_finder_shade_${product.product.index + 1}`,
            })
            );
          } else {
            setShadeLabel(undefined);
          }
        }
      } else if (shadeFinderType === SHADE_FINDER.CONCEALER) {
        const product = selectedProducts.find(
          (x) => x.type === SHADE_FINDER.CONCEALER
        );
        if (product) {
          if (product.product.shadeType === 'ideal') {
            setShadeLabel(intl.formatMessage({
              id: `messages.shade_finder_concealer_ideal`,
            }));
          } else {
            setShadeLabel(intl.formatMessage({
              id: `messages.shade_finder_concealer`,
            }));
          }
        }
      }
    }
  }, [intl, isShadeFinder, selectedProducts, shadeFinderType]);

  const addProduct = (group: Schema.Product, sku: Schema.Sku) => {
    onAddProductSku({
      product: sku,
      productName: localeString(group.product_name) || '',
      productPrice:
        countryPrice(group.product_prices, currentCountry, currentRegion) || '',
      hideProductName: group.hide_product_name || false,
    });
  };

  useEffect(() => {
    if (isShadeFinder) {
      const product = selectedProducts.find((x) => x.type === shadeFinderType);

      group?.skus.forEach((sku: Schema.Sku, index) => {
        if (shadeFinderType === SHADE_FINDER.FOUNDATION) {
          if (product) {
            if (product.product.sku_code === sku.sku_code) {
              addProduct(group, sku);
            }
          } else {
            if (sku.shadeType === 'ideal') {
              addProduct(group, sku);
            }
          }
        }
        if (shadeFinderType === SHADE_FINDER.CONCEALER) {
          if (product === undefined) {
            const matchingFoundation = selectedProducts.find(
              (product) => product.type === SHADE_FINDER.FOUNDATION
            );
            if (
              matchingFoundation?.product.matched_concealer?._ref === sku._id
            ) {
              addProduct(group, sku);
            }
          } else {
            if (product.product.sku_code === sku.sku_code) {
              addProduct(group, sku);
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadeFinderType]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.heading}>
          {isShadeFinder && shadeLabel !== undefined && (
            <span className={styles.shadeLabel}>{shadeLabel}</span>
          )}
          <span>
            {isShadeFinder ? shadeType : localeString(group?.product_copy)}
          </span>
        </div>
        <div className={styles.subheading}>
          {localeString(group?.product_description)}
        </div>
      </div>
      <ul
        className={cn(
          isShadeFinder
            ? group?.skus.length === 2
              ? styles.listCentered
              : styles.list
            : styles.list
        )}
      >
        {group?.skus.map((sku: Schema.Sku) => (
          <li key={sku._id} className={styles.listItem}>
            <Button
              onClick={() => {
                onAddProductSku({
                  product: sku,
                  productName: localeString(group.product_name) || '',
                  productPrice:
                    countryPrice(
                      group.product_prices,
                      currentCountry,
                      currentRegion
                    ) || '',
                  hideProductName: group.hide_product_name || false,
                });
              }}
              style={{ backgroundColor: `${sku.sku_colour}` }}
              className={cn(
                styles.button,
                handleActiveState(sku) && styles.isActive,
                isShadeFinder && styles.isShadeFinder,
                experienceType === 'NAILS' && styles.isNails,
                experienceType === 'NAILS' &&
                  handleActiveState(sku) &&
                  styles.isNailsActive
              )}
            >
              {handleActiveState(sku) && !isShadeFinder && <span>&times;</span>}
              {isShadeFinder && (
                <span className={styles.skuName}>
                  <>{sku.sku_name?.en}</>
                </span>
              )}
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};
