import { Button } from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { PAGE_ACTIONS, PageStateContext } from 'context/PageState';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import styles from './EnableCameraNotice.module.css';

export const EnableCameraNotice: React.FC = () => {
  const { pageDispatch } = useContext(PageStateContext);

  const intl = useIntl();

  const handleCameraNoticeAccept = () => {
    pageDispatch({ type: PAGE_ACTIONS.MENU });
  };

  return (
    <motion.div variants={FADE} {...VARIANT_LABELS} className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.title}>
          {intl.formatMessage({
            id: 'messages.enable_camera_heading',
          })}
        </div>
        <p className={styles.text}>
          {intl.formatMessage({
            id: 'messages.enable_camera_message',
          })}
        </p>
        <Button onClick={handleCameraNoticeAccept} className={styles.button}>
          {intl.formatMessage({
            id: 'messages.enable_camera_button',
          })}
        </Button>
      </div>
    </motion.div>
  );
};
