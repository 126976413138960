import { LANGUAGE_CODES } from 'constants/locales';
import { PAGE_ACTIONS, PageStateContext } from 'context/PageState';
import { ScriptStatus } from 'hooks/useScript';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ExperienceType } from 'types/shared';
import styles from './ExperienceModule.module.css';

interface ExperienceModuleProps {
  type: ExperienceType;
  script: ScriptStatus;
  setIsModuleLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCameraDisabled: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

export const ExperienceModule: React.FC<ExperienceModuleProps> = ({
  type,
  script,
  setIsModuleLoaded,
  setIsCameraDisabled,
}) => {
  const intl = useIntl();
  const [moduleLanguage, setModuleLanguage] = useState<string>('');
  const [isPhotoShow, setIsPhotoShow] = useState<boolean>(false);


  const { pageDispatch } = useContext(PageStateContext);

  useEffect(() => {
    const locale = intl.locale;
    switch (locale) {
      case LANGUAGE_CODES.FRENCH:
        return setModuleLanguage('fra');
      case LANGUAGE_CODES.GERMAN:
        return setModuleLanguage('deu');
      case LANGUAGE_CODES.ITALIAN:
        return setModuleLanguage('ita');
      case LANGUAGE_CODES.SPANISH:
        return setModuleLanguage('esp');
      case LANGUAGE_CODES.JAPANESE:
        return setModuleLanguage('jpn');
      case LANGUAGE_CODES.KOREAN:
        return setModuleLanguage('kor');
      case LANGUAGE_CODES.CHINESE:
        return setModuleLanguage('chs');
      case LANGUAGE_CODES.CHINESE_HK:
        return setModuleLanguage('cht');
      default:
        return setModuleLanguage('enu');
    }
  }, [intl.locale]);


  useEffect(() => {
    const onScriptReady = () => {
      // window.YMK.init({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      //   language: moduleLanguage,
      //   userConsent: 'agree',
      //   skinSmoothStrength: 0,
      //   controlButtonsBottomOffset: 450,
      // });
      console.log('YMK init!!!!')
      window.YMK.init({
        width: window.innerWidth,
        height: window.innerHeight,
        language: moduleLanguage,
        userConsent: 'agree',
        skinSmoothStrength: 1,
        controlButtonsBottomOffset: window.innerHeight / 2 - 50,
      });


      window.YMK.addEventListener('opened', () => {
        console.log('YMK opened!')
      });
      window.YMK.addEventListener('videoLoaded', () => {
        console.log('YMK videoLoaded!')
      });
      window.YMK.addEventListener('cameraOpened', () => {
        console.log('YMK cameraOpened!')
        const vid = document.getElementById("YMK-video-input") as HTMLVideoElement;
        vid.play();
        setIsPhotoShow(false)
      });
      window.YMK.addEventListener('loaded', () => {
        console.log('YMK loaded!')
      });
      window.YMK.addEventListener('photoSaved', () => {
        console.log('YMK photoSaved!')
        setIsPhotoShow(true)
      });


      switch (type) {
        case 'MAKEUP':
        case 'LOOKS':
          window.YMK.open({ loadLiveMode: true });
          break;
        case 'NAILS':
          console.log(window.YMK.openHandAR, 'handAR', navigator.userAgent)
          window.YMK.openHandAR({ loadLiveMode: true });
          break;
        case 'SHADE_FINDER':
          console.log('open shade finder!!!')
          window.YMK.openShadeFinder();
          // window.YMK.open({ loadLiveMode: true });
          break;
        default:
          window.YMK.open({ loadLiveMode: true });
      }
    };

    if (script === 'ready' && moduleLanguage !== '') onScriptReady();
  }, [script, intl.locale, moduleLanguage, type]);

  useEffect(() => {
    const isCameraDisabled = window.YMK.addEventListener('cameraFailed', () => {
      setIsCameraDisabled(true);
    });

    return () => {
      window.YMK.removeEventListener(isCameraDisabled);
    };
  }, [setIsCameraDisabled]);

  useEffect(() => {
    const isModuleLoaded = window.YMK.addEventListener('loaded', () => {
      console.log('module loaded!!!!!')
      setIsModuleLoaded(true);
    });

    return () => {
      window.YMK.removeEventListener(isModuleLoaded);
    };
  }, [setIsModuleLoaded]);

  useEffect(() => {
    const isModuleClosed = window.YMK.addEventListener('closed', () => {
      // pageDispatch({ type: PAGE_ACTIONS.MENU });
      window.location.reload()
    });

    return () => {
      window.YMK.removeEventListener(isModuleClosed);
    };
  }, [pageDispatch]);

  return <div className={styles.wrapper} id="YMK-module" style={isPhotoShow? {zIndex: 9999}: {}}></div>;
};
