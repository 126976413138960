interface IconProps {
  icon: 'globe' | 'back' | 'close' | 'arrow';
  className?: string;
}

export const Icon: React.FC<IconProps> = ({ icon, className }) => {
  switch (icon) {
    case 'globe':
      return (
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/globe.png`}
          alt=""
          className={className}
        />
      );
    case 'close':
      return (
        <svg
          className={className}
          width="44"
          height="43"
          viewBox="0 0 44 43"
          fill="none"
          stroke="currentColor"
        >
          <path d="M2.97363 2L41.9968 41" />
          <path d="M41.0233 2L2.00013 41" />
        </svg>
      );
    case 'back':
      return (
        <svg
          className={className}
          width="49"
          height="44"
          viewBox="0 0 49 44"
          fill="none"
          stroke="currentColor"
        >
          <path d="M22 2L2 22.3509M2 22.3509L22 42M2 22.3509H48.3158" />
        </svg>
      );
    case 'arrow':
      return (
        <svg className={className} viewBox="0 0 8 5">
          <g
            id="arrow"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Vector-4"
              transform="translate(1.000000, 1.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <polygon
                id="Path"
                points="5.64644661 -0.353553391 6.35355339 0.353553391 3 3.70710678 -0.353553391 0.353553391 0.353553391 -0.353553391 3 2.293"
              ></polygon>
            </g>
          </g>
        </svg>
      );
    default:
      break;
  }

  return <>{icon}</>;
};
