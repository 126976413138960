import type { LocaleString } from './schema';

export function localeString(translation: LocaleString | undefined) {
  let locale = window.document.documentElement.lang;

  // console.log('get locale', locale)
  // hotfix for language mixup in cms.
  if (locale === 'zh') locale = 'zhhk';
  if (locale === 'zh-hk') locale = 'zh';
  if (locale === 'zh-CN') locale = 'zhhk';

  return translation
    ? Object.entries(translation).find((x) => x[0] === locale)?.[1] ||
        translation.en
    : '';


}
