import axios from "axios";
import wx from 'weixin-js-sdk'

const CONFIG = {
  SHARE: {
    TITLE: '你的心将带你去往何处？',
    DESCRIPTION: '开启LOEWE罗意威 x 哈尔的移动城堡奇幻之旅',
    ICON: 'wx-share.png'
  },
  APP_ID: 'wxb94d5bc971ef7301',
  API: {
    JS_SDK_SIGN: 'https://wx.precision-site.com/jsapi/index.php'
  },
  DEBUG: false,
  MNP_HOME_PATH: '/pages/home/home?utm_source=HMC_website&utm_medium=internal_referral&utm_campaign=W6_HMC&utm_content=20230201_HMC_experience'
}

export const isWeChat = () => /micromessenger/i.test(navigator.userAgent)
export const isMiniProgram = () => /miniprogram/i.test(navigator.userAgent)
export const isIOS = /(iOS|iPod|iPad|iPhone)/i.test(navigator.userAgent)
export const navigateToMNPHomePage = () => {
  wx.miniProgram.switchTab({ url: CONFIG.MNP_HOME_PATH });
}

export const wechatAudioPlay = (obj) => {
    obj.play()
    return new Promise(resolve => {
        if (
            typeof window.WeixinJSBridge == 'object' &&
            typeof window.WeixinJSBridge.invoke == 'function'
        ) {
            window.WeixinJSBridge.invoke(
                'getNetworkType',
                {},
                (e) => {
                    obj.play()
                    resolve()
                },
                false
            )
        } else {
            if (document.addEventListener) {
                document.addEventListener(
                    'WeixinJSBridgeReady',
                    () => {
                        obj.play()
                        resolve()
                    },
                    false
                )
            }
        }
    })
}

const signWeChatJSSDK = (url) => {
  return new Promise(resolve => {
    axios({
      url: CONFIG.API.JS_SDK_SIGN,
      method: 'post',
      data: {
        appid: CONFIG.APP_ID,
        url,
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log('wxsign res', res.data)
      resolve({
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
      })
    })
  })
}

const enableWeChatJSSDK = data => {
  return new Promise(resolve => {
    wx.config({
      debug: CONFIG.DEBUG,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'checkJsApi',
        // 'onMenuShareTimeline',
        // 'onMenuShareAppMessage',
        'updateAppMessageShareData',
        'updateTimelineShareData',
        // 'hideMenuItems',
        // 'showMenuItems',
      ],
      openTagList: [
        'wx-open-launch-weapp',
      ]
    })
    wx.ready(() => {
      resolve()
    })
  })
}


const setupShare = (path, title = '', desc = '', moments = '', shareImg) => {
  const base = window.location.origin
  const link = `${base}${path}`
  const img = `${base}/${shareImg}`
  wx.updateAppMessageShareData({
    title: title,
    desc: desc,
    link: link,
    imgUrl: img,
    success: () => {
      console.log('share success')
    },
  })
  wx.updateTimelineShareData({
    title: title,
    link: link,
    imgUrl: img,
    success: () => {
      console.log('share success')
    },
  })
}

export const enableWeChatShare = async () => {
  //const url = encodeURIComponent(window.location.href.split('#')[0])
  // const url = window.location.href
  const url = window.originUrl
  const data = await signWeChatJSSDK(url)
  await enableWeChatJSSDK(data)
  setupShare('/', CONFIG.SHARE.TITLE, CONFIG.SHARE.DESCRIPTION, CONFIG.SHARE.TITLE, CONFIG.SHARE.ICON)
}