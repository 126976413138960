export const LANGUAGE_CODES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  GERMAN: 'de',
  SPANISH: 'es',
  ITALIAN: 'it',
  KOREAN: 'ko',
  JAPANESE: 'ja',
  CHINESE: 'zh',
  CHINESE_HK: 'zh-hk',
  ARABIC: 'ar',
};

export const LANGUAGE_URLS = [
  { lang: 'en', url: 'uk/en' },
  { lang: 'fr', url: 'fr/fr' },
  { lang: 'de', url: 'de/de' },
  { lang: 'es', url: 'es/es' },
  { lang: 'it', url: 'it/it' },
  { lang: 'ko', url: 'ko/ko' },
  { lang: 'ja', url: 'ja/ja' },
  { lang: 'zh', url: 'zh/zh' },
  { lang: 'zh-cn', url: 'hk/zh-Hans-HK' },
  { lang: 'ar', url: 'ar/ar' },
];

export const LANGUAGE_NAMES = {
  ENGLISH: 'ENGLISH',
  FRENCH: 'FRANÇAIS',
  GERMAN: 'DEUTSCH',
  SPANISH: 'ESPAÑOL',
  ITALIAN: 'ITALIANO',
  KOREAN: '한국어',
  JAPANESE: '日本語',
  CHINESE: '简体中文',
  CHINESE_HK: '繁體中文',
  ARABIC: 'العربية',
};
