import cn from 'classnames';
import { motion, Variants } from 'framer-motion';
import styles from './Button.module.css';

interface ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  isAnimated?: boolean;
  variants?: Variants;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  style,
  isAnimated,
  variants,
}) => {
  const buttonAttributes = {
    onClick: onClick,
    style: style,
    className: cn(styles.wrapper, className),
  };

  return (
    <>
      {isAnimated ? (
        <motion.button {...buttonAttributes} variants={variants}>
          {children}
        </motion.button>
      ) : (
        <button {...buttonAttributes}>{children}</button>
      )}
    </>
  );
};
