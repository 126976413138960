const dataPath = 'data'

const dataDict = {
  'SHADEFINDER_FOUNDATION_QUERY': 'shadeFinderQueryFoundationResult',
  'SHADEFINDER_CONCEALER_QUERY': 'shadeFinderQueryConcealerResult',
  'SHADEFINDER_LOOK_QUERY': 'shadeFinderLookQueryResult',
  'SHADEFINDER_HOWTO_QUERY': 'howToQueryResults'
}


class sanityProxyClient {
  async fetch(query: 'SHADEFINDER_FOUNDATION_QUERY' | 'SHADEFINDER_CONCEALER_QUERY' | 'SHADEFINDER_LOOK_QUERY' | 'SHADEFINDER_HOWTO_QUERY', params: any) {
    return new Promise(resolve => {
      const path = `${dataPath}/${dataDict[query]}.json?nonStr=${Math.random()}`
      fetch(path)
        .then((response) => {
          return response.json()
        })
        .then(json => {
          resolve(json)
        })
        .catch(console.error);
    })
  }


}

export default new sanityProxyClient()
