export const getRegion = (country: string) => {
  const na = ['US', 'CA', 'BM', 'GL', 'PM'];
  const apac = [
    'TW',
    'ID',
    'PH',
    'VN',
    'LA',
    'KH',
    'MM',
    'BN',
    'NZ',
    'PK',
    'BD',
    'LK',
    'NP',
    'BT',
    'NZ',
  ];
  const emea = [
    'AL',
    'AD',
    'AM',
    'AZ',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'GE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MK',
    'MT',
    'MD',
    'ME',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'GB',
    'BH',
    'EG',
    'IR',
    'IQ',
    'IL',
    'JO',
    'KW',
    'LB',
    'OM',
    'PS',
    'QA',
    'SA',
    'SY',
    'AE',
    'YE',
    'ER',
    'ET',
    'KE',
    'MW',
    'MU',
    'MZ',
    'RW',
    'SC',
    'SO',
    'SS',
    'TZ',
    'UG',
    'ZM',
    'ZW',
    'AO',
    'CM',
    'ST',
    'LY',
    'SD',
    'BW',
    'LS',
    'NA',
    'ZA',
    'SZ',
    'CV',
    'GM',
    'GH',
    'GW',
    'LR',
    'NG',
    'SH',
    'SL',
    'FR',
    'MC',
    'BJ',
    'BF',
    'BI',
    'CF',
    'TD',
    'KM',
    'CG',
    'CI',
    'CD',
    'DJ',
    'GQ',
    'GA',
    'GN',
    'MG',
    'ML',
    'NE',
    'SN',
    'TG',
    'YT',
    'RE',
    'DZ',
    'MR',
    'MA',
    'TN',
    'DE',
    'AT',
    'LI',
  ];
  const eu = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];

  if (na.indexOf(country) > -1) {
    return 'na';
  } else if (apac.indexOf(country) > -1) {
    return 'apac';
  } else if (eu.indexOf(country) > -1) {
    return 'eu';
  } else if (emea.indexOf(country) > -1) {
    return 'emea';
  } else {
    return false;
  }
};
