import {
  ExperienceModule,
  LoaderLarge,
  ShadeFinderUserInterface,
  TryOnUserInterface,
  View,
} from 'components';
import { LooksUserInterface } from 'components/molecules/LooksUserInterface/LooksUserInterface';
import { FADE_EXIT_AFTER_CHILDREN } from 'constants/animations';
import { AnimatePresence } from 'framer-motion';
import { ScriptStatus } from 'hooks/useScript';
import { useState } from 'react';
import { ExperienceType } from 'types/shared';
import styles from './Experience.module.css';

interface ExperienceProps {
  script: ScriptStatus;
  type: ExperienceType;
}

export const Experience: React.FC<ExperienceProps> = ({ script, type }) => {
  const [isModuleLoaded, setIsModuleLoaded] = useState<boolean>(false);
  const [isCameraDisabled, setIsCameraDisabled] = useState<
    boolean | undefined
  >();

  const renderInterface = () => {
    switch (type) {
      case 'MAKEUP':
      case 'NAILS':
        console.log('render nails!!!!!')
        return <TryOnUserInterface type={type} />;
      case 'LOOKS':
        return <LooksUserInterface />;
      case 'SHADE_FINDER':
        return <ShadeFinderUserInterface />;
      default:
        break;
    }

    return <ShadeFinderUserInterface />;
  };

  return (
    <View>
      <AnimatePresence exitBeforeEnter>
        {!isModuleLoaded ? (
          <LoaderLarge
            key="loader"
            version={type}
            variants={FADE_EXIT_AFTER_CHILDREN}
            isCameraDisabled={isCameraDisabled}
          />
        ) : (
          renderInterface()
        )}
      </AnimatePresence>
      <div
        className={styles.background}
        style={{ opacity: isModuleLoaded ? 1 : 0 }}
      ></div>
      <ExperienceModule
        type={type}
        script={script}
        setIsModuleLoaded={setIsModuleLoaded}
        setIsCameraDisabled={setIsCameraDisabled}
      />
    </View>
  );
};
