import { Button } from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './ErrorNotice.module.css';

interface ErrorNoticeProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | React.ReactNode;
  buttonText: string | React.ReactNode;
}

export const ErrorNotice: React.FC<ErrorNoticeProps> = ({
  isOpen,
  setIsOpen,
  message,
  buttonText,
}) => {
  const handleExit = () => {
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key={'Error notice'}
          variants={FADE}
          {...VARIANT_LABELS}
          className={styles.wrapper}
        >
          <div className={styles.modal}>
            <div className={styles.message}>{message}</div>
            <Button onClick={handleExit} className={styles.button}>
              {buttonText}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
