import { Variants } from 'framer-motion';

export const DURATION = 0.5;

export const EASINGS = {
  easeIn: [0.32, 0, 0.67, 0],
  easeOut: [0.33, 1, 0.68, 1],
  easeInOut: [0.65, 0, 0.35, 1],
};

export const VARIANT_LABELS = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

export const DEFAULT_TRANSTION = {
  duration: DURATION,
  ease: EASINGS.easeInOut,
};

export const FADE: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { ...DEFAULT_TRANSTION },
  },
  exit: {
    opacity: 0,
    transition: { ...DEFAULT_TRANSTION },
  },
};

export const FADE_EXIT_AFTER_CHILDREN: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { ...DEFAULT_TRANSTION },
  },
  exit: {
    opacity: 0,
    transition: { ...DEFAULT_TRANSTION, when: 'afterChildren' },
  },
};
