import { Button, TermsOverlay, View } from 'components';
import { FADE } from 'constants/animations';
import { PAGE_ACTIONS } from 'context/PageState';
import { PageStateContext } from 'context/PageState';
import { AnimatePresence, motion } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import styles from './Terms.module.css';

interface TermsProps {
  isShadeFinder: boolean;
}

export const Terms: React.FC<TermsProps> = ({ isShadeFinder }) => {
  const { pageDispatch } = useContext(PageStateContext);
  const intl = useIntl();
  const event = useTrackingEvent();

  const onTermsAccepted = () => {
    event(EventCategories.Beauty, 'Clicks on Agree and Continue', '/', false);
    pageDispatch({ type: PAGE_ACTIONS.EXPERIENCE });
  };

  return (
    <View>
      <AnimatePresence exitBeforeEnter>
        {isShadeFinder ? (
          <TermsOverlay key={'terms overlay'} />
        ) : (
          <motion.div key="terms" variants={FADE} className={styles.wrapper}>
            <div className={styles.text}>
              <p
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'messages.general_terms',
                  }),
                }}
              />
            </div>

            <Button onClick={onTermsAccepted} className={styles.button}>
              {intl.formatMessage({
                id: 'messages.try_on_terms_button',
              })}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </View>
  );
};
