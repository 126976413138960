import cn from 'classnames';
import { Button } from 'components';
import { DEFAULT_TRANSTION } from 'constants/animations';
import { SHADE_FINDER } from 'constants/shadeFinder';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useSettingsStore } from 'store/settingsStore';
import { SelectedProduct } from '../ShadeFinderUserInterface/ShadeFinderUserInterface';
import styles from './InterfaceToggle.module.css';

interface InterfaceToggleProps {
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  selectedSwatches:
    | {
    foundation: SelectedProduct | undefined;
    concealer: SelectedProduct | undefined;
  }
    | undefined;
  isShadeFinder: boolean;
  setIsShadeFinder: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InterfaceToggle: React.FC<InterfaceToggleProps> = ({
                                                                  isShadeFinder,
                                                                  setIsShadeFinder,
                                                                  type,
                                                                  setType,
                                                                  selectedSwatches,
}) => {
  const intl = useIntl();

  const { currentCountry } = useSettingsStore();

  const handleFoundation = () => {
    window.YMK.reset();
    setIsShadeFinder(true);
    setType(SHADE_FINDER.FOUNDATION);
  };

  const handleConcealer = () => {
    window.YMK.reset();
    setIsShadeFinder(true);
    setType(SHADE_FINDER.CONCEALER);
  };

  // const handleShadeFinder = () => {
  //   //reset when going to shadefinder
  //   window.YMK.reset();
  //   setIsShadeFinder(true);
  // };

  const handleLooks = () => {
    setIsShadeFinder(false);
    setType(SHADE_FINDER.LOOKS);
  };

  const buttonsVariants = {
    initial: {
      x: '15%',
      transition: {
        ...DEFAULT_TRANSTION,
      },
    },
    concealer: {
      x: '3%',
      transition: {
        ...DEFAULT_TRANSTION,
      },
    },
    looks: {
      x: '-12%',
      transition: {
        ...DEFAULT_TRANSTION,
      },
    },
  };

  const ActiveMarker: React.FC<{
    selectedColour: string;
    selectedName?: string;
  }> = ({ selectedColour, selectedName }) => {
    if (selectedColour && selectedName) {
      return (
        <span
          style={{ backgroundColor: selectedColour }}
          className={styles.activeMarker}
        >
          {selectedName}
        </span>
      );
    }
    return <></>;
  };

  const setAnimation = () => {
    switch (type) {
      case SHADE_FINDER.FOUNDATION:
        return 'initial';
      case SHADE_FINDER.CONCEALER:
        return 'concealer';
      case SHADE_FINDER.LOOKS:
        return 'looks';
    }
  };

  return (
    <div className={styles.wrapper}>
      <motion.div
        initial="initial"
        animate={setAnimation()}
        variants={buttonsVariants}
        className={styles.buttons}
      >
        {/*<button*/}
        {/*  onClick={handleShadeFinder}*/}
        {/*  className={cn(styles.button, !isShadeFinder && styles.isActive)}*/}
        {/*  dangerouslySetInnerHTML={{*/}
        {/*    __html: intl.formatMessage({*/}
        {/*      id: 'messages.shade_finder_shade_ui_toggle',*/}
        {/*    }),*/}
        {/*  }}*/}
        {/*></button>*/}
        {/*<Button*/}
        {/*  onClick={handleLooks}*/}
        {/*  className={cn(styles.button, isShadeFinder && styles.isActive)}*/}
        {/*>*/}
        {/*  {intl.formatMessage({*/}
        {/*    id: 'messages.shade_finder_looks_ui_toggle',*/}
        {/*  })}*/}
        {/*</Button>*/}
        <button
          onClick={handleFoundation}
          className={cn(
            styles.button,
            type === SHADE_FINDER.FOUNDATION && styles.isActive
          )}
        >
          {intl.formatMessage({id: 'messages.shade_finder_foundation_ui_toggle'})}
          <ActiveMarker
            selectedColour={
              selectedSwatches?.foundation?.product.sku_colour || ''
            }
            selectedName={
              selectedSwatches?.foundation?.product.sku_name?.en || ''
            }
          />
        </button>
        {/*/!* China doesn't launch till January *!/*/}
        {/*{currentCountry !== 'CN' && (*/}
        {/*  <button*/}
        {/*    onClick={handleConcealer}*/}
        {/*    className={cn(*/}
        {/*      styles.button,*/}
        {/*      type === SHADE_FINDER.CONCEALER && styles.isActive*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    {intl.formatMessage({id: 'messages.shade_finder_concealer_ui_toggle'})}*/}
        {/*    <ActiveMarker*/}
        {/*      selectedColour={*/}
        {/*        selectedSwatches?.concealer?.product.sku_colour || ''*/}
        {/*      }*/}
        {/*      selectedName={*/}
        {/*        selectedSwatches?.concealer?.product.sku_name?.en || ''*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </button>*/}
        {/*)}*/}
        <button
          onClick={handleConcealer}
          className={cn(
            styles.button,
            type === SHADE_FINDER.CONCEALER && styles.isActive
          )}
        >
          {intl.formatMessage({id: 'messages.shade_finder_concealer_ui_toggle'})}
          <ActiveMarker
            selectedColour={
              selectedSwatches?.concealer?.product.sku_colour || ''
            }
            selectedName={
              selectedSwatches?.concealer?.product.sku_name?.en || ''
            }
          />
        </button>
        <Button
          onClick={handleLooks}
          className={cn(styles.button, type === 'LOOKS' && styles.isActive)}
        >
          {intl.formatMessage({id: 'messages.shade_finder_looks_ui_toggle'})}
        </Button>
      </motion.div>
    </div>
  );
};
