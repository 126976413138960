import { Button, Icon } from 'components';
import { EASINGS, VARIANT_LABELS } from 'constants/animations';
import { motion, Variants } from 'framer-motion';
import { useIntl } from 'react-intl';
import styles from './LanguageList.module.css';

interface LanguageListProps {
  languages: LanguageProps[];
  selectIsOpen: boolean;
  onExit: () => void;
  onSelect: (code: string) => void;
}

interface LanguageProps {
  code: string;
  name: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const setWrapperVariants = ({ selectIsOpen }: { selectIsOpen: boolean }) => {
  const variants: Variants = {
    animate: {
      x: selectIsOpen ? '0' : '-100%',
      transition: {
        duration: 0,
        when: selectIsOpen ? 'beforeChildren' : 'afterChildren',
      },
    },
  };
  return variants;
};

const setLanguageListVariants = ({
  selectIsOpen,
}: {
  selectIsOpen: boolean;
}) => {
  const variants: Variants = {
    animate: {
      opacity: selectIsOpen ? 1 : 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.07,
        when: selectIsOpen ? 'beforeChildren' : 'afterChildren',
        staggerDirection: selectIsOpen ? -1 : 1,
        ease: EASINGS.easeOut,
      },
    },
  };
  return variants;
};

const setLanguageVariants = ({
  selectIsOpen,
  activeLanguage,
  selectedLanguage,
}: {
  selectIsOpen: boolean;
  activeLanguage: string;
  selectedLanguage: string;
}) => {
  const setLanguageOpacity = (selectedLanguage: string) => {
    if (selectIsOpen && activeLanguage === selectedLanguage) {
      return 0.3;
    } else if (selectIsOpen) {
      return 1;
    } else {
      return 0;
    }
  };

  const variants: Variants = {
    animate: {
      opacity: setLanguageOpacity(selectedLanguage),
      y: selectIsOpen ? '0' : '-200%',
      transition: {
        duration: 0.65,
        ease: selectIsOpen ? EASINGS.easeOut : EASINGS.easeIn,
      },
    },
  };
  return variants;
};

const setCloseButtonVariants = ({
  selectIsOpen,
}: {
  selectIsOpen: boolean;
}) => {
  const variants: Variants = {
    animate: {
      opacity: selectIsOpen ? 1 : 0,
      transition: {
        duration: 0.65,
        ease: selectIsOpen ? EASINGS.easeOut : EASINGS.easeIn,
      },
    },
  };
  return variants;
};

export const LanguageList: React.FC<LanguageListProps> = ({
  languages,
  selectIsOpen,
  onExit,
  onSelect,
}) => {
  const intl = useIntl();
  const activeLanguage = intl.locale;

  return (
    <motion.div
      key="wrapper"
      {...VARIANT_LABELS}
      initial={false}
      variants={setWrapperVariants({ selectIsOpen })}
      className={styles.wrapper}
    >
      <motion.ul
        key="list"
        className={styles.list}
        initial={false}
        variants={setLanguageListVariants({ selectIsOpen })}
      >
        <Button
          key="button"
          onClick={onExit}
          className={styles.closeButton}
          isAnimated={true}
          variants={setCloseButtonVariants({ selectIsOpen })}
        >
          <Icon icon="close" className={styles.closeButtonIcon} />
        </Button>
        {languages.map((language: LanguageProps) => {
          return (
            <li className={styles.listItem} key={language.code}>
              <Button
                isAnimated={true}
                onClick={() => onSelect(language.code)}
                variants={setLanguageVariants({
                  selectIsOpen,
                  activeLanguage,
                  selectedLanguage: language.code,
                })}
                className={styles.language}
              >
                {language.name}
              </Button>
            </li>
          );
        })}
      </motion.ul>
    </motion.div>
  );
};
