import { VARIANT_LABELS } from 'constants/animations';
import { motion, Variants } from 'framer-motion';
import styles from './TextRotate.module.css';

interface TextRotateProps {
  text: string;
}

const textVariants: Variants = {
  animate: {
    y: ['-50%', '0%', '50%'],
    opacity: [0, 1, 0],
    transition: {
      repeat: Infinity,
      duration: 2,
    },
  },
};

export const TextRotate: React.FC<TextRotateProps> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <motion.div
        {...VARIANT_LABELS}
        variants={textVariants}
        className={styles.text}
      >
        {text}
      </motion.div>
    </div>
  );
};
