import { LANGUAGE_CODES } from 'constants/locales';
import flattenObject from 'utils/flattenObject';
import dataAR from '../locales/ar.json';
import dataDE from '../locales/de.json';
import dataEN from '../locales/en.json';
import dataES from '../locales/es.json';
import dataFR from '../locales/fr.json';
import dataIT from '../locales/it.json';
import dataJA from '../locales/ja.json';
import dataKO from '../locales/ko.json';
import dataZHHK from '../locales/zh-hk.json';
import dataZH from '../locales/zh.json';

type i18nConfig = {
  defaultLocale: string;
  availableLocales: string[];
};

const locales = [
  dataEN,
  dataFR,
  dataDE,
  dataES,
  dataIT,
  dataAR,
  dataJA,
  dataKO,
  dataZHHK,
  dataZH,
];
const flatLocales = locales.map((locale) => flattenObject(locale));

const [
  messagesEN,
  messagesFR,
  messagesDE,
  messagesES,
  messagesIT,
  messagesAR,
  messagesJA,
  messagesKO,
  messagesZHHK,
  messagesZH,
] = flatLocales;

const messagesMap: Record<string, Record<string, string>> = {
  en: messagesEN,
  fr: messagesFR,
  de: messagesDE,
  es: messagesES,
  it: messagesIT,
  ar: messagesAR,
  ja: messagesJA,
  ko: messagesKO,
  'zh-hk': messagesZHHK,
  zh: messagesZH,
};

class LocaleService {
  private readonly defaultLocale: string;
  private readonly availableLocales: string[];
  constructor(config: i18nConfig) {
    this.defaultLocale = config.defaultLocale;
    this.availableLocales = config.availableLocales;
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  getDefaultLocale() {
    return this.defaultLocale;
  }

  getMessages(language: string) {
    if (this.availableLocales.includes(language)) {
      return messagesMap[language];
    }
    return messagesMap[this.defaultLocale];
  }
}

// export default new LocaleService({
//   defaultLocale: LANGUAGE_CODES.ENGLISH,
//   availableLocales: [
//     LANGUAGE_CODES.ENGLISH,
//     LANGUAGE_CODES.FRENCH,
//     LANGUAGE_CODES.GERMAN,
//     LANGUAGE_CODES.SPANISH,
//     LANGUAGE_CODES.ITALIAN,
//     LANGUAGE_CODES.KOREAN,
//     LANGUAGE_CODES.JAPANESE,
//     LANGUAGE_CODES.CHINESE,
//     LANGUAGE_CODES.CHINESE_HK,
//     LANGUAGE_CODES.ARABIC,
//   ],
// });
export default new LocaleService({
  defaultLocale: LANGUAGE_CODES.CHINESE,
  availableLocales: [

    // LANGUAGE_CODES.FRENCH,
    // LANGUAGE_CODES.GERMAN,
    // LANGUAGE_CODES.SPANISH,
    // LANGUAGE_CODES.ITALIAN,
    // LANGUAGE_CODES.KOREAN,
    // LANGUAGE_CODES.JAPANESE,
    LANGUAGE_CODES.CHINESE,
    LANGUAGE_CODES.ENGLISH,
    // LANGUAGE_CODES.CHINESE_HK,
    // LANGUAGE_CODES.ARABIC,
  ],
});