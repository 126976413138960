import { urlFor } from 'sanity/images';
import styles from './ProductImage.module.css';

interface ProductImageProps {
  imageId: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({ imageId }) => {
  return <img src={urlFor(imageId).url()} alt="" className={styles.wrapper} />;
};
