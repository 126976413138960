/**
 * Take the sku from the cms and check country and replace
 * tokens for country specific data (CN & US)
 * EXAMPLE:
 * WW = 7201699PFWW9110
 * US = 7201689PFUS9110
 * CN = 7201639PFCH9110
 * @param sku
 * @param country
 */
const formatForCountry = (sku: string, country: string) => {
  let retSku = sku;
  if (country === 'CN') {
    retSku = retSku.replace('699', '639').replace('WW', 'CN');
  }
  if (country === 'US') {
    retSku = retSku.replace('699', '689').replace('WW', 'US');
  }
  console.log(retSku);
  return retSku;
};

/**
 * Return formatted url for when using gucci beauty app
 * @param sku
 * @returns
 */
export const getShopUrl = (
  sku: string,
  formatSku: boolean,
  country: string
) => {
  return `https://shop.gucci.com/${
    formatSku ? formatForCountry(sku, country) : sku
  }?utm_campaign=shadefindervto_website&utm_content=shadefindervto_website&utm_medium=website&utm_source=shadefindervto_website`;
};

/**
 * When on gucci.com, post a message to
 * the window.parent for the add to cart functionality
 * @param sku
 */
export const addToCart = async (
  sku: string,
  formatSku: boolean,
  country: string
) => {
  const retSku = await fetch(
    `https://prod-catalog-api.guccidigital.io/v1/products/${
      formatSku ? formatForCountry(sku, country) : sku
    }`
  )
    .then((response) => response.json())
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    .then((response: any) => {
      return response.variants[0].sku;
    });

  const eventIframe = {
    event: 'addToCart',
    styleCode: sku,
    sku: retSku,
  };

  window.parent.postMessage(eventIframe, '*');
};
