import cn from 'classnames';
import styles from './Logo.module.css';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <img
      className={cn(styles.wrapper, className)}
      src={`${process.env.PUBLIC_URL}/images/gucci-beauty-logo.png`}
      alt="Gucci Beauty Logo"
    />
  );
};
