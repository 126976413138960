import { createContext, Dispatch } from 'react';

export const VIDEO_ACTIONS = {
  INTRO: 'INTRO',
  MENU_OPEN: 'MENU OPEN',
  MENU_CLOSE: 'MENU CLOSE',
  RESET: 'RESET',
};

interface VideoState {
  introIsActive: boolean;
  menuOpenIsActive: boolean;
  menuCloseIsActive: boolean;
  resetIsActive: boolean;
}

interface VideoAction {
  type: string;
}

export const initialVideoState = {
  introIsActive: false,
  menuOpenIsActive: false,
  menuCloseIsActive: false,
  resetIsActive: true,
};

export const VideoStateContext = createContext<{
  videoState: VideoState;
  videoDispatch: Dispatch<VideoAction>;
}>({
  videoState: initialVideoState,
  videoDispatch: () => null,
});

export const videoStateReducer = (
  videoState: VideoState,
  action: VideoAction
) => {
  switch (action.type) {
    case VIDEO_ACTIONS.INTRO: {
      return {
        introIsActive: true,
        menuOpenIsActive: false,
        menuCloseIsActive: false,
        resetIsActive: false,
      };
    }
    case VIDEO_ACTIONS.MENU_OPEN: {
      return {
        introIsActive: false,
        menuOpenIsActive: true,
        menuCloseIsActive: false,
        resetIsActive: false,
      };
    }
    case VIDEO_ACTIONS.MENU_CLOSE: {
      return {
        introIsActive: false,
        menuOpenIsActive: false,
        menuCloseIsActive: true,
        resetIsActive: false,
      };
    }
    case VIDEO_ACTIONS.RESET: {
      return {
        introIsActive: false,
        menuOpenIsActive: false,
        menuCloseIsActive: false,
        resetIsActive: true,
      };
    }
  }
  return videoState;
};
