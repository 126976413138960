import cn from 'classnames';
import { Button } from 'components';
import { nanoid } from 'nanoid';
import { useEffect, useRef } from 'react';
import { localeString } from 'sanity/locales';
import type * as Schema from '../../../sanity/schema';
import styles from './Looks.module.css';

interface LooksProps {
  looks?: Array<Schema.Look>;
  onLookSelected: (look: string) => void;
  selectedLook: string;
  categoryName: string | undefined;
  isOpen: boolean;
  hasSingleCategory?: boolean;
}

export const Looks: React.FC<LooksProps> = ({
  looks,
  onLookSelected,
  selectedLook,
  categoryName,
  isOpen,
  hasSingleCategory,
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollerRef.current) scrollerRef.current.scrollLeft = 0;
  }, [categoryName]);

  return (
    <>
      <div className={styles.wrapper}>
        <div
          ref={scrollerRef}
          className={cn(styles.inner, isOpen && styles.isOpen)}
        >
          <ul className={styles.list}>
            {hasSingleCategory && (
              <li
                className={styles.category}
                dangerouslySetInnerHTML={{
                  __html: categoryName || '',
                }}
              />
            )}
            {looks?.map((look: Schema.Look) => (
              <li key={nanoid()} className={styles.listItem}>
                <Button
                  onClick={() => {
                    onLookSelected(look.look_id || '');
                  }}
                  className={cn(
                    styles.button,
                    selectedLook === look.look_id && styles.isActive
                  )}
                >
                  {localeString(look.look_name)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
