import { VideoData } from './VideoSequence';

export const videos: Array<VideoData> = [
  {
    filename: 'intro-top',
    startPoint: 'intro',
  },
  {
    filename: 'intro-bottom',
    startPoint: 'intro',
  },
  {
    filename: 'menu-open-top',
    startPoint: 'menu-open',
  },
  {
    filename: 'menu-open-bottom',
    startPoint: 'menu-open',
  },
  {
    filename: 'menu-close-top',
    startPoint: 'menu-close',
  },
  {
    filename: 'menu-close-bottom',
    startPoint: 'menu-close',
  },
];
