import { LanguageSelect, ShadeFinderMenu, TryOnMenu, View } from 'components';
import { VIDEO_ACTIONS, VideoStateContext } from 'context/VideoState';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ExperienceType } from 'types/shared';

interface MenuProps {
  isShadeFinder: boolean;
  onLanguageSelect: (languageCode: string) => void;
  onLinkSelect: (type: ExperienceType) => void;
}

export const Menu: React.FC<MenuProps> = ({
  isShadeFinder,
  onLanguageSelect,
  onLinkSelect,
}) => {
  const { videoDispatch } = useContext(VideoStateContext);

  const intl = useIntl();

  const tryOnLinks = [
    {
      text: intl.formatMessage({
        id: 'messages.try_on_menu_makeup',
      }),
      type: 'MAKEUP' as ExperienceType,
    },
    {
      text: intl.formatMessage({
        id: 'messages.try_on_menu_nails',
      }),
      type: 'NAILS' as ExperienceType,
    },
    {
      text: intl.formatMessage({
        id: 'messages.try_on_menu_looks',
      }),
      type: 'LOOKS' as ExperienceType,
    },
  ];

  useEffect(() => {
    videoDispatch({ type: VIDEO_ACTIONS.MENU_OPEN });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLinkSelected = (type: ExperienceType) => {
    onLinkSelect(type);
    videoDispatch({ type: VIDEO_ACTIONS.MENU_CLOSE });
  };

  return (
    <View>
      {isShadeFinder ? (
        <>
          <ShadeFinderMenu onLinkSelected={onLinkSelected} />
        </>
      ) : (
        <TryOnMenu menuLinks={tryOnLinks} onLinkSelected={onLinkSelected} />
      )}
      <LanguageSelect
        onSelect={onLanguageSelect}
        isShadeFinder={isShadeFinder}
      />
    </View>
  );
};
