import { Video } from 'components';
import { VideoProps } from 'components/atoms/Video/Video';
import { PAGE_ACTIONS, PageStateContext } from 'context/PageState';
import { VIDEO_ACTIONS, VideoStateContext } from 'context/VideoState';
import { useContext, useEffect, useState } from 'react';
import { videos } from './data';

export type VideoData = Omit<
  VideoProps,
  'feature' | 'handleOnLoad' | 'isPlaying' | 'handleOnEnd'
>;

interface VideoSequenceProps {
  isShadeFinder: boolean;
}

export const VideoSequence: React.FC<VideoSequenceProps> = ({
  isShadeFinder,
}) => {
  const { videoState, videoDispatch } = useContext(VideoStateContext);
  const { pageDispatch } = useContext(PageStateContext);

  const [videosLoaded, setVideosLoaded] = useState<number>(0);

  const handleOnLoad = () => {
    setVideosLoaded((videosLoaded) => videosLoaded + 1);
  };

  useEffect(() => {
    if (videosLoaded === videos.length) {
      return pageDispatch({ type: PAGE_ACTIONS.INTRO });
    }
  }, [pageDispatch, videosLoaded]);

  const handleReset = () => {
    videoDispatch({ type: VIDEO_ACTIONS.RESET });
    pageDispatch({ type: PAGE_ACTIONS.TERMS });
  };

  const handleIsPlaying = (startPoint: VideoProps['startPoint']) => {
    switch (startPoint) {
      case 'intro':
        return videoState.introIsActive;
      case 'menu-open':
        return videoState.menuOpenIsActive;
      case 'menu-close':
        return videoState.menuCloseIsActive;
      default:
        break;
    }
  };

  const handleOnEnd = (startPoint: VideoProps['startPoint']) => {
    switch (startPoint) {
      case 'intro':
        return pageDispatch({ type: PAGE_ACTIONS.MENU });
      case 'menu-close':
        return handleReset();
      default:
        break;
    }
  };

  return (
    <>
      {videos.map((video: VideoData) => (
        <Video
          key={video.filename}
          feature={isShadeFinder ? 'shade-finder' : 'try-on'}
          filename={video.filename}
          startPoint={video.startPoint}
          handleOnLoad={handleOnLoad}
          isPlaying={handleIsPlaying(video.startPoint)}
          handleOnEnd={handleOnEnd}
        />
      ))}
    </>
  );
};
