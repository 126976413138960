import type { Prices } from './schema';

export function countryPrice(
  price: Prices | undefined,
  country: string,
  region: string
) {
  let retVal =
    price &&
    Object.entries(price).find((x) => x[0] === country.toUpperCase())?.[1];

  if (!retVal && region === 'eu') {
    retVal = price && price.EU;
  }

  return retVal;
}
