import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DeviceNotice } from 'components';
import { ROUTES } from 'constants/routes';
// import { useQuery } from 'hooks';
import { ShadeFinderPage, TryOnPage } from 'pages';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import i18n from 'services/i18n';
// import { useSettingsStore } from 'store/settingsStore';
// import { GeolocationResponse } from 'types/shared';
// import VConsole from 'vconsole'
// new VConsole()

const queryClient = new QueryClient();

const App = () => {
  const [language, setLanguage] = useState(i18n.getDefaultLocale());
  const messages = i18n.getMessages(language);
  // const regionsBlocked = ['Illinois'];
  const [showBlockMessage, setShowBlockMessage] = useState<boolean>(false);
  const [showDeviceNotice, setShowDeviceNotice] = useState<boolean>(true);
  // const query = useQuery();
  // const { setIsGucci } = useSettingsStore();
  //
  // useEffect(() => {
  //   const checkGeolocation = async () => {
  //     const regionName = await fetch('geolocation.json')
  //       .then((response) => response.json())
  //       .then((response: GeolocationResponse) => {
  //         return response['cloudfront-viewer-country-region-name'];
  //       });
  //
  //     if (regionName && regionsBlocked.includes(regionName)) {
  //       setShowBlockMessage(true);
  //     }
  //   };
  //   if (query.get('country')) {
  //     setIsGucci(true);
  //     return;
  //   }
  //   checkGeolocation();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const hostname = window.location.hostname;
  //   if (hostname === 'ggshadefinder.gucci.com') setShowDeviceNotice(false);
  // }, []);

  const handleLanguageSelect = (languageCode: string) => {
    window.document.documentElement.lang = languageCode;
    setLanguage(languageCode);
  };

  useEffect(() => {
    // let detectedLanguage = navigator.language.toLowerCase() || language;
    let detectedLanguage = language;

    if (
      detectedLanguage !== 'zh-cn' &&
      detectedLanguage !== 'zh-tw' &&
      detectedLanguage !== 'zh-hk'
    ) {
      const twoDigitCode = detectedLanguage.split('-')[0];
      detectedLanguage = i18n.getAvailableLocales().includes(twoDigitCode)
        ? twoDigitCode
        : detectedLanguage;
    }

    // if the language detected is zh-tw (Taiwan)
    // map to zh-hk for traditional chinese
    if (detectedLanguage === 'zh-tw') {
      detectedLanguage = 'zh-hk';
    }

    // if the language detected is zh-cn (China)
    // map to zh for simplified chinese
    if (detectedLanguage === 'zh-cn') {
      detectedLanguage = 'zh';
    }

    // Map language codes from query param to language codes used in the app
    // const langFromParam = query.get('language');
    //
    // if (langFromParam) {
    //   switch (langFromParam) {
    //     case 'en_gb':
    //     case 'en_au':
    //       detectedLanguage = 'en';
    //       break;
    //     case 'zh_hk':
    //       detectedLanguage = 'zh-hk';
    //       break;
    //     case 'zh':
    //       detectedLanguage = 'zh';
    //       break;
    //     default:
    //       detectedLanguage = langFromParam;
    //   }
    // }

    handleLanguageSelect(detectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        messages={messages}
        key={language}
        locale={language}
        defaultLocale={i18n.getDefaultLocale()}
      >
        {process.env.NODE_ENV === 'production' && showBlockMessage ? (
          <div
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            This site is unavailable in your region
          </div>
        ) : (
          <Routes>
            {/*<Route*/}
            {/*  path="/"*/}
            {/*  element={*/}
            {/*    <TryOnPage handleLanguageSelect={handleLanguageSelect} />*/}
            {/*  }*/}
            {/*/>*/}
            <Route
              path="/"
              element={
                <ShadeFinderPage handleLanguageSelect={handleLanguageSelect} />
              }
            />
            {/*<Route*/}
            {/*  path="*"*/}
            {/*  element={*/}
            {/*    <TryOnPage handleLanguageSelect={handleLanguageSelect} />*/}
            {/*  }*/}
            {/*/>*/}
          </Routes>
        )}
        {process.env.NODE_ENV === 'production' && showDeviceNotice && (
          <DeviceNotice />
        )}
      </IntlProvider>
    </QueryClientProvider>
  );
};

export default App;
