import cn from 'classnames';
import { Button } from 'components';
import { EventCategories, useTrackingEvent } from 'hooks';
import { localeString } from 'sanity/locales';
import { LooksQueryResult } from 'sanity/types';
import type * as Schema from '../../../sanity/schema';
import styles from './LooksCategoryList.module.css';

interface LooksCategoryListProps {
  looksCategories?: LooksQueryResult;
  lookCategory?: string;
  setCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const LooksCategoryList: React.FC<LooksCategoryListProps> = ({
  looksCategories,
  lookCategory,
  setCategory,
}) => {
  const event = useTrackingEvent();
  return (
    <ul className={styles.wrapper}>
      {looksCategories?.map((category: Schema.LookCategory) => (
        <li key={category._id} className={styles.listItem}>
          <Button
            onClick={() => {
              event(
                EventCategories.Beauty,
                `Clicks on Looks category`,
                category.category_name?.en || '',
                false
              );
              setCategory(category._id);
            }}
            className={cn(
              styles.button,
              lookCategory === category._id && styles.isActive
            )}
          >
            {localeString(category.category_name)}
          </Button>
        </li>
      ))}
    </ul>
  );
};
