import cn from 'classnames';
import { Product } from 'components';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useEffect, useRef } from 'react';
import { localeString } from 'sanity/locales';
import { countryPrice } from 'sanity/prices';
import { Prices } from 'sanity/schema';
import { LookSkuType, LookType } from 'sanity/types';
import { useSettingsStore } from 'store/settingsStore';
import { addToCart, getShopUrl } from 'utils/shopping';
import { SelectedProduct } from '../ShadeFinderUserInterface/ShadeFinderUserInterface';
import styles from './LookItems.module.css';

interface LookItemsProps {
  look?: LookType;
  isOpen: boolean;
  shadeProduct?: SelectedProduct;
}

type Look = Omit<LookSkuType, 'isShoppable'>;

export const LookItems: React.FC<LookItemsProps> = ({
  look,
  isOpen,
  shadeProduct,
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const { isShoppable, isGucci, currentCountry, currentRegion } =
    useSettingsStore();
  const event = useTrackingEvent();

  useEffect(() => {
    if (scrollerRef.current) scrollerRef.current.scrollTop = 0;
  }, [look]);

  const getProductPrice = (price: Prices | undefined) => {
    return countryPrice
      ? countryPrice(price, currentCountry, currentRegion) || ''
      : '';
  };

  const getCartButton = () => {
    return (
      <>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="11.95"
            y1="2.96631"
            x2="16.31"
            y2="2.96631"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <line
            x1="14.0591"
            y1="0.75"
            x2="14.0591"
            y2="5.11396"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1333 7.91996H9.89332V6.05329C9.89332 5.02662 8.68003 4.18662 7.09336 4.18662C5.5067 4.18662 4.29336 4.93329 4.29336 6.05329V7.91996H1.77336C0.840029 7.91996 0.280029 8.38662 0.280029 9.03996L0.746696 16.32H13.2533L13.8133 9.13329C13.8133 8.38662 13.0666 7.91996 12.1333 7.91996ZM5.69336 6.05329C5.7867 5.95996 6.25336 5.58662 7.09336 5.58662C7.93336 5.58662 8.40003 5.86662 8.49336 6.05329V7.91996H5.69336V6.05329ZM2.05336 14.92H11.9466L12.32 9.41329H12.1333H1.77336H1.68003L2.05336 14.92Z"
            fill="black"
          />
        </svg>
      </>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          ref={scrollerRef}
          className={cn(styles.itemInner, isOpen && styles.isOpen)}
        >
          <div className={styles.header}>
            {look && (
              <div className={styles.lookName}>
                {localeString(look.look_name)}
              </div>
            )}
          </div>
          {look?.skus.map((sku: Look) => (
            <div
              className={cn(styles.product, isShoppable && styles.isShoppable)}
            >
              <Product
                imageId={sku.sku_image?.asset._ref || ''}
                skuName={localeString(sku.sku_name) || ''}
                productName={localeString(sku.product?.product_name) || ''}
                isLookItem={true}
                key={sku._id}
                hideProductName={sku.product?.hide_product_name || false}
                productPrice={getProductPrice(sku.product?.product_prices)}
              />
              {isShoppable && getProductPrice(sku.product?.product_prices) && (
                <>
                  {isGucci ? (
                    <button
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                      onClick={() => {
                        event(
                          EventCategories.ShadeFinder,
                          'Clicks on Shop - Gucci Looks',
                          `${sku.sku_name?.en || ''} |  ${
                            sku.product?.product_name?.en || ''
                          }`,
                          isGucci
                        );
                        addToCart(sku.sku_code || '', false, currentCountry);
                      }}
                    >
                      {getCartButton()}
                    </button>
                  ) : (
                    <a
                      href={getShopUrl(
                        sku.sku_code || '',
                        false,
                        currentCountry
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                    >
                      {getCartButton()}
                    </a>
                  )}
                </>
              )}
            </div>
          ))}
          {shadeProduct && (
            <div
              className={cn(styles.product, isShoppable && styles.isShoppable)}
            >
              <Product
                imageId={shadeProduct?.product.sku_image?.asset._ref || ''}
                skuName={localeString(shadeProduct?.product.sku_name) || ''}
                productName={shadeProduct?.productName || ''}
                isLookItem={true}
                hideProductName={false}
                productPrice={shadeProduct?.productPrice || ''}
              />
              {isShoppable && shadeProduct?.productPrice && (
                <>
                  {isGucci ? (
                    <button
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                      onClick={() => {
                        event(
                          EventCategories.ShadeFinder,
                          'Clicks on Shop - Gucci Looks',
                          `${shadeProduct.product.sku_name?.en || ''} |  ${
                            shadeProduct.productName || ''
                          }`,
                          isGucci
                        );
                        addToCart(
                          shadeProduct.product.sku_code || '',
                          false,
                          currentCountry
                        );
                      }}
                    >
                      {getCartButton()}
                    </button>
                  ) : (
                    <a
                      href={getShopUrl(
                        shadeProduct.product.sku_code || '',
                        false,
                        currentCountry
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                    >
                      {getCartButton()}
                    </a>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
