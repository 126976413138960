import { LoaderLarge, View } from 'components';
import { FADE } from 'constants/animations';
import { ExperienceType } from 'types/shared';

interface LoadingProps {
  type: ExperienceType;
}

export const Loading: React.FC<LoadingProps> = ({ type }: LoadingProps) => {
  return (
    <View>
      <LoaderLarge version={type} variants={FADE} />
    </View>
  );
};
