import cn from 'classnames';
import {
  DEFAULT_TRANSTION,
  EASINGS,
  VARIANT_LABELS,
} from 'constants/animations';
import { motion, Variants } from 'framer-motion';
import styles from './LoaderSmall.module.css';

interface LoaderSmallProps {
  color: 'black' | 'black transparent' | 'white';
  className?: string;
}

const wrapperVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { ...DEFAULT_TRANSTION, staggerChildren: 0.1 },
  },
  exit: {
    opacity: 0,
    transition: { ...DEFAULT_TRANSTION },
  },
};

const dotVariants: Variants = {
  initial: {
    y: '-50%',
  },
  animate: {
    y: '50%',
    transition: {
      duration: 0.5,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: EASINGS.easeInOut,
    },
  },
};

const setDotColor = (color: LoaderSmallProps['color']) => {
  switch (color) {
    case 'black':
      return styles.isBlack;
    case 'black transparent':
      return styles.isBlackTransparent;
    case 'white':
      return styles.isWhite;
    default:
      break;
  }
};

export const LoaderSmall: React.FC<LoaderSmallProps> = ({
  color,
  className,
}) => {
  return (
    <motion.div
      {...VARIANT_LABELS}
      variants={wrapperVariants}
      className={cn(styles.wrapper, className)}
    >
      <motion.span
        className={cn(styles.dot, setDotColor(color))}
        variants={dotVariants}
      />
      <motion.span
        className={cn(styles.dot, setDotColor(color))}
        variants={dotVariants}
      />
      <motion.span
        className={cn(styles.dot, setDotColor(color))}
        variants={dotVariants}
      />
    </motion.div>
  );
};
