import cn from 'classnames';
import { FADE_EXIT_AFTER_CHILDREN, VARIANT_LABELS } from 'constants/animations';
import { motion } from 'framer-motion';
import styles from './View.module.css';

interface ViewProps {
  children: React.ReactNode;
  onAnimationComplete?: () => void;
  className?: string;
}

export const View: React.FC<ViewProps> = ({
  children,
  className,
  onAnimationComplete,
}) => {
  return (
    <motion.section
      {...VARIANT_LABELS}
      variants={FADE_EXIT_AFTER_CHILDREN}
      onAnimationComplete={onAnimationComplete}
      className={cn(styles.wrapper, className)}
    >
      {children}
    </motion.section>
  );
};
