import { Logo, View } from 'components';
import { VIDEO_ACTIONS, VideoStateContext } from 'context/VideoState';
import { useContext, useEffect } from 'react';

export const Intro: React.FC = () => {
  const { videoDispatch } = useContext(VideoStateContext);

  useEffect(() => {
    videoDispatch({ type: VIDEO_ACTIONS.INTRO });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <Logo />
    </View>
  );
};
