import cn from 'classnames';
import { ProductGroup } from 'components';
import { SelectedProduct } from 'components/molecules/TryOnUserInterface/TryOnUserInterface';
import { useEffect, useRef } from 'react';
import type * as Schema from '../../../sanity/schema';
import type { TryOnQueryResult } from '../../../sanity/types';
import styles from './ProductGroupList.module.css';

interface ProductGroupListProps {
  productCategories?: TryOnQueryResult;
  productCategory?: string;
  onAddProductSku: (product: SelectedProduct) => void;
  productIsSelected: boolean;
  selectedProducts: Array<SelectedProduct>;
  detailIsSelected?: boolean;
  isShadeFinder?: boolean;
  shadeFinderType?: string;
}

export const ProductGroupList: React.FC<ProductGroupListProps> = ({
  productCategories,
  productCategory,
  onAddProductSku,
  productIsSelected,
  selectedProducts,
  detailIsSelected,
  isShadeFinder,
                                                                    shadeFinderType
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollerRef.current) scrollerRef.current.scrollLeft = 0;
  }, [productCategory]);

  const setInnerStyles = (
    productIsSelected: boolean,
    detailIsSelected: boolean | undefined
  ) => {
    if (productIsSelected && detailIsSelected) {
      return styles.topLeftIsRounded;
    } else if (productIsSelected && !detailIsSelected) {
      return styles.topLeftIsNotRounded;
    } else {
      return;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        ref={scrollerRef}
        className={cn(
          styles.inner,
          setInnerStyles(productIsSelected, detailIsSelected)
        )}
      >
        {productCategories
          ?.find(
            (category: Schema.Category) => category._id === productCategory
          )
          ?.products?.map((group: Schema.Product) => (
            <ProductGroup
              key={group._id}
              group={group as Schema.Product & { skus: Array<Schema.Sku> }}
              onAddProductSku={onAddProductSku}
              selectedProducts={selectedProducts}
              isShadeFinder={isShadeFinder}
              shadeFinderType={shadeFinderType}
            />
          ))}
      </div>
    </div>
  );
};
