import ReactGA from 'react-ga4';

export enum EventCategories {
  'ShadeFinder' = 'Shade Finder VTO',
  'Beauty' = 'Beauty VTO',
}
export const useTrackingEvent = () => {
  const event = (
    category: string,
    action: string,
    label: string,
    isGucci: boolean
  ) => {
    if (isGucci) {
      const eventIframe = {
        event: 'iframeEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      };
      window.parent.postMessage(eventIframe, '*');
    } else {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
        nonInteraction: false,
      });
    }
  };
  return event;
};
